import React from 'react';
import { string } from 'prop-types';

import FacetedSearch from '../../../components-v2/faceted/faceted.mobile';
import ToolbarItem from './toolbar-item.mobile';

const ToolbarSort = ({ namespace, iconComponent }) => (
  <ToolbarItem className={`${namespace}__action--filter`} namespace={namespace}>
    <FacetedSearch icon={iconComponent} />
  </ToolbarItem>
);

ToolbarSort.propTypes = {
  iconComponent: string,
  namespace: string,
};

ToolbarSort.defaultProps = {
  namespace: '',
};

export default ToolbarSort;
