import React from 'react';
import { string } from 'prop-types';

import { useSearch } from '../../../hooks/context';
import AvailableFilters from '../../filters/available/available-filters.mobile';
import { FilterTooltip } from '../../../components-v2/sidebar/components/filters-tooltip/mobile';
import ToolbarItem from './toolbar-item.mobile';

const renderToolbarFilter = (namespace, filtering_options) => {
  const { labels, available_filters, url, tooltip } = filtering_options;

  return (
    <ToolbarItem className={`${namespace}__action--filter`} namespace={namespace}>
      <AvailableFilters title={labels.filter_by} labels={labels} filters={available_filters} url={url} />
      {tooltip && (
        <FilterTooltip tooltip={tooltip}>
          <div className={`${namespace}__tooltip-trigger`} />
        </FilterTooltip>
      )}
    </ToolbarItem>
  );
};

const ToolbarFilters = ({ namespace }) => {
  const { filtering_options } = useSearch();

  return filtering_options ? renderToolbarFilter(namespace, filtering_options) : null;
};

ToolbarFilters.propTypes = {
  namespace: string,
};

export default ToolbarFilters;
