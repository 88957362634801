import React from 'react';
import { string } from 'prop-types';

import { useNextLayout, onChangeLayout } from '../../context/layout';
import ViewChange from '../../view-change/view-change.mobile';
import ToolbarItem from './toolbar-item.mobile';
import { useStaticProps } from '../../context/static-props';

const renderToolbarItem = (namespace, nextLayout) => (
  <ToolbarItem namespace={namespace}>
    <ViewChange onChangeLayout={onChangeLayout} nextLayout={nextLayout} />
  </ToolbarItem>
);

const ToolbarSort = ({ namespace }) => {
  const { changeLayout } = useStaticProps();
  const nextLayout = useNextLayout();

  return changeLayout ? renderToolbarItem(namespace, nextLayout) : null;
};

ToolbarSort.propTypes = {
  namespace: string.isRequired,
};

export default ToolbarSort;
