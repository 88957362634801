import React from 'react';
import { string } from 'prop-types';

import classnames from 'classnames';

import { BuildIcon } from '../utils';

const namespace = 'ui-search-icon ui-search-icon--mosaic';

const IconMosaic = (props) => (
  <BuildIcon {...props} className={classnames(namespace, props.className)} viewBox="0 0 32 32">
    <path d="M0 0h32v32h-32v-32zM2.667 2.667v16h10.667v-16h-10.667zM2.667 21.333v8h10.667v-8h-10.667zM16 2.667v8h13.333v-8h-13.333zM16 13.333v16h13.333v-16h-13.333z" />
  </BuildIcon>
);

IconMosaic.propTypes = {
  className: string,
};

export default IconMosaic;
