import React, { useState } from 'react';
import { func, shape, bool, string } from 'prop-types';

import { ListItem } from '@andes/list';

import Modal from '../../../modal/base.mobile';
import useModal from '../../../../hooks/use-modal';
import IconChevron from '../../../../components-v2/icons/sources/chevron';
import SimpleSelectionSort from './simple-selection-sort.pi';
import { useSearch } from '../../../../hooks/context';

const namespace = 'ui-search-filter-selector';

const SortFilter = ({ applyFilter, currentValue }) => {
  const [isOpen, openModal, hideModal] = useModal();
  const { sorting_options } = useSearch();
  const [currentSelection, setCurrentSelection] = useState(null);
  const selectedValue = currentValue || sorting_options.sorts.filter((sort) => sort.selected)[0];

  const selectFilter = (value) => {
    const keyFilter = { sort: value || currentSelection };

    applyFilter(keyFilter);
    hideModal();
  };

  return (
    <div>
      <ListItem
        className={`${namespace}__filter-item`}
        key={sorting_options.title}
        title={sorting_options.label}
        description={selectedValue.name}
        rightContent={<IconChevron />}
        onClick={openModal}
      />
      <Modal
        modalClassName={`${namespace}--modal pi sort`}
        label={sorting_options.label}
        modalTitle={sorting_options.label}
        modalUrl="#filter"
        hideModal={hideModal}
        openModal={openModal}
        open={isOpen}
      >
        <div className={`${namespace}__selection-panel-container`}>
          <SimpleSelectionSort
            sorting_options={sorting_options}
            selectFilter={selectFilter}
            currentValue={selectedValue}
            setSelection={setCurrentSelection}
            filterName={sorting_options.label}
          />
        </div>
      </Modal>
    </div>
  );
};

SortFilter.propTypes = {
  applyFilter: func.isRequired,
  currentValue: shape({
    id: string,
    url: string,
    name: string,
    selected: bool,
    has_disclaimer: bool,
  }),
};

SortFilter.defaultProps = {
  currentValue: undefined,
};

export default SortFilter;
