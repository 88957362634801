import { useCallback, useState } from 'react';

import useSearchUrl from '../../../../hooks/use-search-url';
import useSelectedFiltersSearch from '../../../../hooks/use-selected-filters-search';
import { useSearch } from '../../../../hooks/context';
import { setHideModal as setHideModalBase } from '../../../context/filters';
import { getAppliedSort, getAppliedFilters, useHasFiltersState } from '../../../context/filters/helpers';
import { FILTERS, OFFICIAL_STORE_ALL, OFFICIAL_STORE_KEYNAME, FILTER_GROUP_SEPARATOR } from '../../../../constants';

const { OFFICIAL_STORE, NEIGHBORHOOD } = FILTERS.IDS;
const { GROUP } = FILTERS.TYPES;

// We need to send the official stores' key in spanish to the middleware
const buildDynamicFilterKey = ({ key, dynamicValue, selectedValue, filter }) => {
  if (filter?.type === GROUP) {
    return selectedValue?.target?.id.split(FILTER_GROUP_SEPARATOR)[0];
  }

  return key === OFFICIAL_STORE && dynamicValue === OFFICIAL_STORE_ALL ? OFFICIAL_STORE_KEYNAME : key;
};

const buildValueCandidate = (selectedValue) => {
  let valueCandidate;

  const selectedInputValue = selectedValue?.id?.split('');

  if (Array.isArray(selectedValue)) {
    valueCandidate = selectedValue.map(({ id }) => id).join(',');
  } else if (selectedValue?.target?.id) {
    valueCandidate = selectedValue.target.id;
  } else {
    valueCandidate = selectedInputValue.join('');
  }

  return valueCandidate;
};

const buildDynamicFilterValue = ({ appliedFilters, selectedValue, key, filter }) => {
  if (filter?.type === GROUP) {
    return selectedValue?.target?.id.split(FILTER_GROUP_SEPARATOR)[1];
  }

  const valueCandidate = buildValueCandidate(selectedValue);

  return key === NEIGHBORHOOD && appliedFilters.neighborhood
    ? `${appliedFilters.neighborhood},${valueCandidate}`
    : valueCandidate;
};

const createFilterReducer = (appliedFilters, filtersToBeApplied, availableFilters) => (acc, key) => {
  const filter = availableFilters.find(({ id }) => id === key);
  const selectedValue = filtersToBeApplied[key];
  const filterValue = buildDynamicFilterValue({ appliedFilters, selectedValue, key, filter });
  const filterKey = buildDynamicFilterKey({ dynamicValue: filterValue, selectedValue, key, filter });

  return {
    ...acc,
    [filterKey]: filterValue,
  };
};

const useDynamicFilters = ({ appliedFilters, availableFilters }) => {
  const { isLoadingResults, sorting_options: sortingOptions } = useSearch();
  const [isLoading, getUrl] = useSearchUrl();
  const setHideModal = useHasFiltersState() ? setHideModalBase : () => {};
  const [filtersToBeApplied, setFiltersToBeApplied] = useState({});
  const doFilterSearch = useSelectedFiltersSearch(getUrl);

  const applyFilter = useCallback(
    (item) => setFiltersToBeApplied((prevFilters) => ({ ...prevFilters, ...item })),
    [setFiltersToBeApplied],
  );

  const clearFilters = useCallback(() => setFiltersToBeApplied({}), [setFiltersToBeApplied]);

  const doSearchFn = () => {
    const appliedFiltersObj = getAppliedFilters(appliedFilters);
    const getFiltersToBeApplied = createFilterReducer(appliedFiltersObj, filtersToBeApplied, availableFilters);
    const filtersToBeAppliedObj = Object.keys(filtersToBeApplied).reduce(getFiltersToBeApplied, {});

    const unifiedFilters = {
      ...getAppliedSort(sortingOptions.sorts),
      ...appliedFiltersObj,
      ...filtersToBeAppliedObj,
    };

    return doFilterSearch(unifiedFilters);
  };

  return {
    applyFilter,
    clearFilters,
    doSearch: doSearchFn,
    filtersToBeApplied,
    isLoading: isLoading || isLoadingResults,
    setHideModal,
  };
};

export { useDynamicFilters };
