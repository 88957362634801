import React from 'react';
import { string } from 'prop-types';

import classnames from 'classnames';

import Link from '../../components-v2/link';
import IconOrderToolbar from '../../components-v2/icons/sources/toolbar-order';

const namespace = 'ui-search-sort';

const SortMobile = ({ className, label, url }) => (
  <Link href={url} isInternal className={classnames(namespace, className, 'sort-option-menu--link')} title={label}>
    <IconOrderToolbar />
    {label}
  </Link>
);

SortMobile.propTypes = {
  className: string,
  label: string.isRequired,
  url: string.isRequired,
};
SortMobile.defaultProps = {
  className: '',
};

export default SortMobile;
