import React, { useState, useCallback } from 'react';
import { string, bool, func, shape } from 'prop-types';

import classnames from 'classnames';
import { Switch } from '@andes/switch';

import { LAYOUTS } from '../../constants';
import { trackClickEvent } from '../map/track';
import { useSearch } from '../../hooks/context';
import useMapConfig from '../map/hooks/use-map-config';

const namespace = 'ui-search-animated-switch-v2';

const onClickDefaultProp = () => {};

const AnimatedSwitchV2 = ({ className = null, checked, onClick = onClickDefaultProp, inputProps, srLabel }) => {
  const [isChecked, setIsChecked] = useState(checked);
  const { layout_options } = useSearch();
  const { searchTrack } = useMapConfig();
  const track = {
    melidata: {
      melidata_track: {
        ...searchTrack,
        type: 'view',
      },
    },
  };

  const handleClick = useCallback(
    (e) => {
      if (layout_options?.current === LAYOUTS.TYPE_MAP) {
        trackClickEvent(null, track.melidata);
      }

      setIsChecked((prevChecked) => !prevChecked);

      if (onClick) {
        onClick(e);
      }
    },
    [layout_options, onClick, track.melidata],
  );

  return (
    <Switch
      checked={isChecked}
      onChange={handleClick}
      labelPosition="right"
      className={classnames(namespace, `${namespace}--small`, className)}
      srLabel={srLabel}
      inputProps={inputProps}
      label=""
    />
  );
};

AnimatedSwitchV2.propTypes = {
  checked: bool.isRequired,
  className: string,
  inputProps: shape({
    'aria-labelledby': string,
  }),
  srLabel: string,
  onClick: func,
};

AnimatedSwitchV2.displayName = 'AnimatedSwitchV2';

export default AnimatedSwitchV2;
