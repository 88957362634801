/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable security/detect-unsafe-regex */
import React, { useState, useContext, useEffect, useCallback } from 'react';
import { string, func, objectOf } from 'prop-types';

import classnames from 'classnames';
import Form from '@andes/form';
import TextField from '@andes/textfield';

import { CurrencyContext } from '../../../context/currency';
import { useStaticProps } from '../../../context/static-props';

const namespace = 'ui-search-range-filter';

/* eslint-disable no-restricted-globals */
const RangeFilter = ({ filterId, setSelection, setExpanded, labels, suffix, currentValue, setShowButton }) => {
  const [rangeState, setRangeState] = useState({
    minimum: currentValue?.customRange ? currentValue.customRange.min : '',
    maximum: currentValue?.customRange ? currentValue.customRange.max : '',
  });

  const setRange = useCallback(
    (range, value) => {
      setRangeState({
        ...rangeState,
        [range]: value,
      });
    },
    [rangeState],
  );

  const { selectedCurrency } = useContext(CurrencyContext);
  const { deviceOs } = useStaticProps();
  const rangeNames = labels.range_names;
  const isPriceFilter = filterId === 'price';
  const prefixLabel = isPriceFilter ? selectedCurrency.symbol : '';
  const onlyNumbersPattern = '[0-9]*';
  const thousandsRegex = /(\.\d+)|\B(?=(\d{3})+(?!\d))/g;

  const thousandsSeparator = (num) => num.toString().replace(thousandsRegex, labels.number_separator);
  const labelWithPrefix = (input) => `${prefixLabel}${thousandsSeparator(input)}${suffix}`;

  const formatCustomRangeLabel = (min, max) => {
    const rangeLabels = { min: labelWithPrefix(min), max: labelWithPrefix(max) };

    if (min === 0) {
      return rangeNames.only_to.replace('$to', rangeLabels.max);
    }

    if (max === 0) {
      return rangeNames.only_from.replace('$from', rangeLabels.min);
    }

    return `${rangeLabels.min} ${labels.range_separator} ${rangeLabels.max}`;
  };

  const inputToNumber = (input) => {
    const number = parseInt(input, 10);

    return isNaN(number) ? 0 : number;
  };

  const itemRange = () => {
    let suffixLabel = suffix;

    if (isPriceFilter) {
      suffixLabel = selectedCurrency.id;
    }

    const rangeMin = inputToNumber(rangeState.minimum);
    const rangeMax = rangeState.maximum === '' ? '*' : inputToNumber(rangeState.maximum);

    const keyRange = {
      id: `${rangeMin}${suffixLabel}-${rangeMax}${suffixLabel}`,
      name: formatCustomRangeLabel(rangeMin, rangeMax),
      customRange: { min: rangeMin, max: rangeMax },
    };

    setSelection(keyRange);
  };

  const handleFocus = () => {
    setExpanded(true);
    setShowButton(true);
  };

  const handleBlur = () => {
    const { minimum, maximum } = rangeState;

    setExpanded(false);
    setShowButton(minimum || maximum);
  };

  useEffect(itemRange, [rangeState, selectedCurrency]);

  return (
    <div className={classnames({ [`${namespace}__container`]: deviceOs === 'android' })}>
      <h3 className={`${namespace}__header`}>{labels.range_title}</h3>
      <Form className={`${namespace}__content`}>
        <TextField
          type="number"
          pattern={onlyNumbersPattern}
          className={`${namespace}__text-field`}
          label={labels.minimum}
          suffix={suffix}
          value={rangeState.minimum.toString()}
          prefix={prefixLabel}
          onBlur={handleBlur}
          onFocus={handleFocus}
          onChange={({ target }) => setRange('minimum', target.value)}
          data-testid="test-input-minimum"
        />
        <span className={`${namespace}__separator`}>{labels.range_separator}</span>
        <TextField
          type="number"
          pattern={onlyNumbersPattern}
          className={`${namespace}__text-field`}
          label={labels.maximum}
          prefix={prefixLabel}
          suffix={suffix}
          value={rangeState.maximum.toString()}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={({ target }) => setRange('maximum', target.value)}
          data-testid="test-input-maximum"
        />
      </Form>
    </div>
  );
};
/* eslint-enable react-hooks/exhaustive-deps */
/* eslint-enable security/detect-unsafe-regex */
/* eslint-enable no-restricted-globals */

RangeFilter.propTypes = {
  currentValue: objectOf(string),
  filterId: string.isRequired,
  labels: objectOf(string).isRequired,
  setExpanded: func.isRequired,
  setSelection: func.isRequired,
  setShowButton: func.isRequired,
  suffix: string.isRequired,
};

RangeFilter.defaultProps = {
  isExpanded: false,
  currentValue: null,
};

export default RangeFilter;
