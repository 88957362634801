import React from 'react';

import { useSearch } from '../../hooks/context';
import ToolDynamicFilters from './components/tool-dynamic-filters.mobile';
import ToolSearch from './components/tool-faceted-search.mobile';
import ToolbarMobile from './toolbar.mobile';
import ToolSort from './components/tool-sort.mobile';
import { LANDING_TYPES } from '../../pages/search/components/views/main/constants';
import { MapLinkMobile } from '../../components-v2/sidebar/components/map';

const namespace = 'ui-search-toolbar';

const Toolbar = () => {
  const {
    filtering_options,
    faceted_search: facetedSearch,
    new_faceted_search: newFacetedSearch,
    map_link,
    landing,
  } = useSearch();

  const isFacetedSearchEnabled = !!(facetedSearch || newFacetedSearch);

  const { applied_filters } = filtering_options;
  const landingType = landing.type;

  return (
    <ToolbarMobile showBorder={applied_filters && applied_filters.length > 0} namespace={namespace}>
      {map_link ? <MapLinkMobile map_link={map_link} /> : <ToolSort namespace={namespace} />}
      {isFacetedSearchEnabled && landingType !== LANDING_TYPES.OFFICIAL_STORE && <ToolSearch namespace={namespace} />}
      <ToolDynamicFilters namespace={namespace} />
    </ToolbarMobile>
  );
};

export default Toolbar;
