import React from 'react';
import { string } from 'prop-types';

import { useSearch } from '../../../hooks/context';
import Sort from '../../sort/sort.mobile';
import ToolbarItem from './toolbar-item.mobile';

const renderToolbar = (namespace, sorting_options) => (
  <ToolbarItem namespace={namespace}>
    <Sort {...sorting_options} />
  </ToolbarItem>
);

const ToolbarSort = ({ namespace }) => {
  const { sorting_options } = useSearch();

  return sorting_options ? renderToolbar(namespace, sorting_options) : null;
};

ToolbarSort.propTypes = {
  namespace: string.isRequired,
};

export default ToolbarSort;
