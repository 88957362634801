import { useCallback, useState } from 'react';

import { buildInitialState, deserializeState } from 'vis-faceted-search/helpers';

import useModal from '../../../hooks/use-modal';
import useVisFacetedSearch from '../../../hooks/use-vis-faceted-search';

const useFacetedMobile = () => {
  const [isOpen, openModal, hideModal] = useModal();
  const { renderConfig, initialState, onAction } = useVisFacetedSearch();
  // the faceted component's state internally is serialized for objects, use this to have the same behavior
  const [facetedState, setFacetedState] = useState(() => buildInitialState(renderConfig, initialState));

  const onChange = useCallback(
    (internalState) => {
      setFacetedState(internalState);
    },
    [setFacetedState],
  );

  const handlerAction = useCallback(async () => {
    await onAction(null, deserializeState(facetedState));
  }, [onAction, facetedState]);

  return {
    isOpen,
    openModal,
    hideModal,
    renderConfig,
    initialState,
    onAction,
    facetedState,
    onChange,
    handlerAction,
  };
};

export default useFacetedMobile;
