/* eslint-disable react/prop-types */

import React, { useState, useEffect, useRef } from 'react';
import { string, arrayOf, shape, func, objectOf, bool } from 'prop-types';

import classnames from 'classnames';
import Button from '@andes/button';
import { ListItem } from '@andes/list';

import Modal from '../../../modal/base.mobile';
import useModal from '../../../../hooks/use-modal';
import SimpleSelection from './simple-selection.pi';
import MultiSelection from './multi-selection.pi';
import PriceSelection from './price-filter.pi';
import RangeFilter from './range-filter.pi';
import HighlightedFilter from '../../available/highlighted-filter';
import { FILTERS } from '../../../../constants';
import { CurrencyProvider } from '../../../context/currency';
import IconChevron from '../../../../components-v2/icons/sources/chevron';

const namespace = 'ui-search-filter-selector';

const REF_TIMEOUT_MILLISECONDS = 100;

const choosePanel = (id) => {
  switch (id) {
    case 'neighborhood':
      return MultiSelection;
    case 'price':
      return PriceSelection;

    default:
      return SimpleSelection;
  }
};

const ConfirmButton = ({ show, makeSelection, text }) =>
  show ? (
    <div className={`${namespace}__button-container`}>
      <Button className={`${namespace}__button-footer`} size="large" onClick={makeSelection}>
        {text}
      </Button>
    </div>
  ) : null;

ConfirmButton.propTypes = {
  makeSelection: func.isRequired,
  show: bool.isRequired,
  text: string.isRequired,
};

const DefaultFilter = ({
  filterId,
  name,
  expandedValues,
  applyFilter,
  labels,
  currentValue,
  currencies,
  convertedValues,
  suffix,
  type,
}) => {
  const [isOpen, openModal, hideModal] = useModal();
  const SelectionPanel = choosePanel(filterId);

  const [isRangeExpanded, setIsRangeExpanded] = useState(false);
  const [currentSelection, setCurrentSelection] = useState(null);
  const [showButton, setShowButton] = useState(filterId === 'neighborhood');

  const isRangeFilter = type === 'range' || type === 'PRICE_WITH_CONVERSION';
  const divScrollRef = useRef();

  const selectFilter = (value) => {
    const filter = {
      ...value,
      // eslint-disable-next-line no-underscore-dangle
      name: value?.target?._wrapperState?.initialValue?.name,
    };
    const keyFilter = { [filterId]: value ? filter : currentSelection };

    applyFilter(keyFilter);
    hideModal();
  };

  const renderLabel = (value) => {
    if (value?.name) {
      return value.name;
    }

    return Array.isArray(value)
      ? value.map((item, index) => {
          if (index === value.length - 1) {
            return `${item.name}`;
          }

          return `${item.name}, `;
        })
      : null;
  };

  const scrollBottom = () => {
    if (divScrollRef.current) {
      divScrollRef.current.scrollIntoView(false);
    }
  };

  useEffect(() => {
    // Se agrega un timeout para el delay que se toma en reconocer lo que hay dentro de divScrollRef.current
    setTimeout(() => {
      scrollBottom();
    }, REF_TIMEOUT_MILLISECONDS);
  }, [isRangeExpanded]);

  return (
    <CurrencyProvider currencies={currencies}>
      <ListItem
        className={`${namespace}__filter-item`}
        key={filterId}
        title={name}
        description={renderLabel(currentValue)}
        rightContent={<IconChevron />}
        onClick={openModal}
      />
      <Modal
        modalClassName={`${namespace}--modal pi`}
        label={name}
        modalTitle={name}
        modalUrl="#filter"
        hideModal={hideModal}
        openModal={openModal}
        open={isOpen}
        actions={{
          fixed: true,
          primary: <ConfirmButton show={showButton} makeSelection={() => selectFilter()} text={labels.continue} />,
        }}
      >
        <div
          className={classnames(`${namespace}__selection-panel-container`, {
            [`${namespace}__selection-multipanel-container`]: filterId === 'neighborhood',
          })}
        >
          <SelectionPanel
            filterId={filterId}
            namespace={namespace}
            expandedValues={expandedValues}
            labels={labels}
            selectFilter={selectFilter}
            setSelection={setCurrentSelection}
            currentValue={currentValue}
            filterName={name}
            convertedValues={convertedValues}
          />
        </div>
        {isRangeFilter && (
          <div className={`${namespace}__selection-rangepanel-container`} ref={divScrollRef}>
            <RangeFilter
              filterId={filterId}
              setExpanded={setIsRangeExpanded}
              setSelection={setCurrentSelection}
              labels={labels}
              suffix={suffix}
              currentValue={currentValue}
              setShowButton={setShowButton}
            />
          </div>
        )}
      </Modal>
    </CurrencyProvider>
  );
};

DefaultFilter.propTypes = {
  applyFilter: func.isRequired,
  convertedValues: objectOf(
    arrayOf(
      shape({
        id: string,
        url: string,
        name: string,
      }),
    ),
  ),
  currencies: arrayOf(
    shape({
      id: string,
      symbol: string,
      selected: bool,
    }),
  ),
  currentValue: shape({
    id: string,
    url: string,
    name: string,
  }),
  expandedValues: arrayOf(objectOf(string)),
  filterId: string,
  labels: objectOf(string).isRequired,
  name: string,
  suffix: string,
  type: string,
};

DefaultFilter.defaultProps = {
  expandedValues: [],
  filterId: '',
  name: '',
  convertedValues: undefined,
  currentValue: undefined,
  currencies: undefined,
  suffix: '',
  type: '',
};

const Filter = (props) => {
  const { type } = props;

  switch (type) {
    case FILTERS.TYPES.HIGHLIGHTED:
      return <HighlightedFilter {...props} expanded_values={props.expandedValues} />;

    default:
      return <DefaultFilter {...props} />;
  }
};

export default Filter;
