import React from 'react';
import { string, func, shape, arrayOf, objectOf } from 'prop-types';

import classnames from 'classnames';
import Button from '@andes/button';

import { useCurrency } from '../hooks/use-currency';
import SimpleSelection from './simple-selection.pi';

const namespace = 'ui-search-price-filter';

const PriceFilter = ({ filterName, selectFilter, currentValue, filterId, convertedValues, filterType }) => {
  const [values, selectedCurrency, currencies, switchCurrency] = useCurrency(convertedValues);

  const handleCurrencySwitch = (id) => {
    switchCurrency(id);
  };

  const handleKeyCurrencySwitch = (ev, id) => {
    if (ev.key === 'Enter') {
      handleCurrencySwitch(id);
    }
  };

  return (
    <div className={`${namespace}__container`}>
      <ul className={`${namespace}__currency-selector`}>
        {currencies.map(({ id, symbol }) => (
          <li
            className={classnames(`${namespace}__currency-item`, {
              [`${namespace}__currency-item--selected`]: id === selectedCurrency.id,
            })}
            key={`price-selectedor-${id}`}
            name={id}
          >
            <Button
              className={`${namespace}__button`}
              onClick={() => handleCurrencySwitch(id)}
              onKeyPress={(ev) => handleKeyCurrencySwitch(ev, id)}
            >
              {symbol}
            </Button>
          </li>
        ))}
      </ul>
      <SimpleSelection
        namespace={namespace}
        expandedValues={values}
        filterId={filterId}
        selectFilter={selectFilter}
        currentValue={currentValue}
        filterType={filterType}
        filterName={filterName}
      />
    </div>
  );
};

PriceFilter.propTypes = {
  convertedValues: objectOf(
    arrayOf(
      shape({
        id: string,
        url: string,
        name: string,
      }),
    ),
  ).isRequired,
  currentValue: shape({
    id: string,
    name: string,
  }),
  filterId: string.isRequired,
  filterName: string,
  filterType: string,
  selectFilter: func,
};

PriceFilter.defaultProps = {
  filterName: '',
  filterType: '',
  expandedValues: '',
  selectFilter: '',
  currentValue: undefined,
};

export default PriceFilter;
