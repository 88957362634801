import React from 'react';
import { string } from 'prop-types';

import classnames from 'classnames';

import { BuildIcon } from '../utils';

const namespace = 'ui-search-icon ui-search-icon--order';

const IconOrder = (props) => (
  <BuildIcon {...props} className={classnames(namespace, props.className)} viewBox="0 0 32 32">
    <path d="M6.857 23.527l-3.705-3.705-1.616 1.616 6.464 6.464 6.464-6.462-1.616-1.616-3.705 3.701v-18.953h-2.286v18.955zM22.857 8.473l-3.705 3.705-1.616-1.616 6.464-6.464 0.809 0.807 5.655 5.657-1.616 1.616-3.705-3.703v18.953h-2.286v-18.955z" />
  </BuildIcon>
);

IconOrder.propTypes = {
  className: string,
};

export default IconOrder;
