/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { arrayOf, string, object, objectOf, bool } from 'prop-types';

import { Button } from '@andes/button';
import { List } from '@andes/list';

import Modal from '../../modal/base.mobile';
import useModal from '../../../hooks/use-modal';
import IconFilterToolbar from '../../../components-v2/icons/sources/toolbar-filter';
import { ChunkAppliedFiltersContainer } from '../applied/chunk-applied-filters.mobile';
import AvailableFilter from './components/available-filter.pi';
import SortFilter from './components/sort-filter.pi';
import { useDynamicFilters } from './hooks/use-dynamic-filters';

const namespace = 'ui-search-dynamic-filters';

const DynamicFiltersMobile = ({ appliedFilters, filters, labels, url, icon }) => {
  const [isOpen, openModal, hideModal] = useModal();
  const { applyFilter, clearFilters, doSearch, filtersToBeApplied, isLoading, setHideModal } = useDynamicFilters({
    appliedFilters,
    availableFilters: filters,
  });

  const closeModal = () => {
    clearFilters();
    hideModal();
  };

  useEffect(() => {
    if (isOpen && hideModal) {
      setHideModal(() => hideModal);
    }
  }, [isOpen]);

  return (
    <Modal
      modalClassName={`${namespace}__main-modal pi`}
      icon={icon ? <IconFilterToolbar /> : null}
      label={labels.filter}
      modalTitle={labels.filter_by}
      modalUrl="#filter"
      url={url}
      hideModal={closeModal}
      openModal={openModal}
      open={isOpen}
      actions={{
        fixed: true,
        primary: (
          <Button className={`${namespace}__button-footer`} disabled={isLoading} size="large" onClick={doSearch}>
            {labels.apply}
          </Button>
        ),
      }}
      withLink
    >
      <Button className={`${namespace}__header-suffix`} modifier="transparent" onClick={clearFilters}>
        {labels.clean}
      </Button>
      <ChunkAppliedFiltersContainer className={`${namespace}__applied-filters pi`} isInternal />

      <div className={`${namespace}__available-list sorts-container pi`}>
        <List className={`${namespace}__available-list sorts pi`} selectable={false}>
          <SortFilter applyFilter={applyFilter} currentValue={filtersToBeApplied.sort} />
        </List>
      </div>

      <List className={`${namespace}__available-list pi`} selectable={false}>
        {filters.map(({ id, name, type, expanded_values, currencies, values_with_conversion, suffix }) => (
          <AvailableFilter
            name={name}
            filterId={id}
            type={type}
            labels={labels}
            expandedValues={expanded_values}
            key={id}
            applyFilter={applyFilter}
            currentValue={filtersToBeApplied[`${id}`]}
            currencies={currencies}
            convertedValues={values_with_conversion}
            suffix={suffix}
          />
        ))}
      </List>
    </Modal>
  );
};

DynamicFiltersMobile.propTypes = {
  appliedFilters: arrayOf(object),
  filters: arrayOf(object),
  icon: bool,
  labels: objectOf(string).isRequired,
  url: string,
};

DynamicFiltersMobile.defaultProps = {
  appliedFilters: [],
  filters: [],
  url: '',
  icon: false,
};

export default DynamicFiltersMobile;
