import React, { useState, useEffect } from 'react';
import { string, func, arrayOf, object } from 'prop-types';

import Checkbox from '@andes/checkbox';

const namespace = 'multi-selection-filters';

const MultiSelection = ({ filterName, expandedValues, setSelection, currentValue }) => {
  const [selectedValues, setSelectedValues] = useState(new Set(currentValue || []));

  const setSelected = (value, action) => {
    const newValues = new Set(selectedValues);

    newValues[`${action}`](value);
    setSelectedValues(newValues);
  };

  const toggleSelection = (value, ev) => {
    const action = ev.target.checked ? 'add' : 'delete';

    setSelected(value, action);
  };

  useEffect(() => {
    const valueList = Array.from(selectedValues);

    setSelection(valueList);
  }, [setSelection, selectedValues]);

  return (
    <div className={`${namespace} ${namespace}__container`}>
      {expandedValues.map((value) => (
        <div className={`${namespace} ${namespace}__item-container`} key={value.id}>
          <Checkbox
            className={`${namespace}__item-checkbox`}
            inputProps={{ name: filterName }}
            label={value.name}
            value={value.name}
            checked={selectedValues.has(value)}
            onChange={(e) => toggleSelection(value, e)}
            labelPosition="left"
          />
        </div>
      ))}
    </div>
  );
};

MultiSelection.propTypes = {
  currentValue: arrayOf(object),
  expandedValues: arrayOf(object),
  filterName: string,
  setSelection: func.isRequired,
};

MultiSelection.defaultProps = {
  filterName: '',
  expandedValues: '',
  namespace: '',
  currentValue: [],
};

export default MultiSelection;
