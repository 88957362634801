import { filterSearch } from '../components/context/filters/helpers';
import useClientSideOptions from './use-client-side-options';
import { FILTERS } from '../constants';

const assignLocation = (url) => {
  window.location = url;
};
const {
  IDS: { CITY, STATE, NEIGHBORHOOD, LOCATION_ID, DISPLAY_TYPE },
} = FILTERS;
const LOCATION_FILTERS = [CITY, LOCATION_ID, NEIGHBORHOOD, STATE];

const getLocationFilterId = (filters) => LOCATION_FILTERS.find((filterId) => !!filters[filterId]) || null;

const hasDisplayType = (url) => url.toLowerCase().indexOf(DISPLAY_TYPE.toLowerCase()) !== -1;

// The faceted search service discards the DisplayType param, so we
// manually add it to avoid a layout change.
const addDisplayType = (url, currentDisplayType) =>
  hasDisplayType(url) ? url : `${url}/_${DISPLAY_TYPE}_${currentDisplayType}`;

const useSelectedFiltersSearch = (getUrlFn) => {
  const { isClientSideSearch, clientSideFilters } = useClientSideOptions();

  const doFilterSearch = (filters) => {
    const handleClientSideSearch = (url) =>
      filterSearch(addDisplayType(url, clientSideFilters[DISPLAY_TYPE]), getLocationFilterId(filters))();

    return getUrlFn({ ...filters, ...clientSideFilters }).then((url) => {
      if (url) {
        const handleSearch = isClientSideSearch ? handleClientSideSearch : assignLocation;

        return handleSearch(url);
      }

      return null;
    });
  };

  return doFilterSearch;
};

export default useSelectedFiltersSearch;
