import React from 'react';

import { loadable } from 'nordic/lazy';

import { useSearch } from '../../hooks/context';
import ToolAvailableFilters from './components/tool-available-filters.mobile';
import ToolViewChange from './components/tool-change-layout.mobile';
import ToolbarMobile from './toolbar.mobile';
import { LANDING_TYPES } from '../../pages/search/components/views/main/constants';
import { LAYOUTS } from '../../constants';
import IconSearchLight from '../../components-v2/icons/sources/search-light';

const ToolSort = loadable(async () => import('./components/tool-sort.mobile'));
const Spotlight = loadable(async () => import('../spotlight/spotlight.mobile'));
const MapLinkMobile = loadable(async () =>
  import('../../components-v2/sidebar/components/map/components/map-link.mobile'),
);
const ToolSearch = loadable(async () => import('./components/tool-faceted-search.mobile'));

const namespace = 'ui-search-toolbar';

const Toolbar = () => {
  const {
    filtering_options,
    new_faceted_search: newFacetedSearch,
    spotlight,
    top_switch_filters,
    map_link,
    landing,
    layout_options,
  } = useSearch();
  const isFacetedSearchEnabled = !!newFacetedSearch?.faceted_config;
  const isMapLayout = layout_options.current === LAYOUTS.TYPE_MAP;

  const { applied_filters } = filtering_options;
  const showBorder = !!(top_switch_filters || (applied_filters && applied_filters.length > 0));
  const hasSpotlight = typeof spotlight !== 'undefined';
  const landingType = landing.type;

  return (
    <ToolbarMobile showBorder={showBorder} namespace={namespace} hasSpotlight={hasSpotlight}>
      {!map_link && <ToolSort namespace={namespace} />}
      {hasSpotlight ? <Spotlight toolbarVariant /> : <ToolViewChange namespace={namespace} />}
      {map_link && <MapLinkMobile map_link={map_link} />}
      {isMapLayout && isFacetedSearchEnabled && landingType !== LANDING_TYPES.OFFICIAL_STORE && (
        <ToolSearch namespace={namespace} iconComponent={<IconSearchLight color="#3483fa" />} />
      )}
      <ToolAvailableFilters namespace={namespace} />
    </ToolbarMobile>
  );
};

export default Toolbar;
