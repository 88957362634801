import React from 'react';
import { func, shape, string } from 'prop-types';

import classnames from 'classnames';

import IconGalleryToolbar from '../../components-v2/icons/sources/toolbar-gallery';
import IconListToolbar from '../../components-v2/icons/sources/toolbar-list.delete';
import IconMosaicToolbar from '../../components-v2/icons/sources/toolbar-mosaic';
import Link from '../../components-v2/link';
import { mosaic, gallery, stack } from './constants';

const namespace = 'ui-search-view-change';

const getLayoutIconComponent = (id) => {
  switch (id) {
    case mosaic:
      return <IconMosaicToolbar />;
    case gallery:
      return <IconGalleryToolbar />;
    case stack:

    // fall through
    default:
      return <IconListToolbar />;
  }
};

const ViewChange = ({ nextLayout, onChangeLayout }) => (
  <Link
    href={`${nextLayout.url_host}${nextLayout.url_path}`}
    keepLayout={false}
    className={classnames(namespace, `${namespace}__link`)}
    onClick={(e) => {
      e.preventDefault();
      onChangeLayout();
    }}
    title={nextLayout.label}
  >
    <div className={`${namespace}__icon`}>{getLayoutIconComponent(nextLayout.id)}</div>
    <span className={`${namespace}__label`}>{nextLayout.label}</span>
  </Link>
);

ViewChange.propTypes = {
  nextLayout: shape({
    id: string.isRequired,
    label: string.isRequired,
    url_host: string.isRequired,
    url_path: string.isRequired,
  }).isRequired,
  onChangeLayout: func.isRequired,
};

export default ViewChange;
