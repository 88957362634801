import React from 'react';
import { string } from 'prop-types';

import classnames from 'classnames';

import { BuildIcon } from '../utils';

const namespace = 'ui-search-icon ui-search-icon--search-light';
const ICON_ID = 'ic_search_light';

const IconSearchLight = (props) => (
  <BuildIcon
    {...props}
    className={classnames(namespace, props.className)}
    viewBox="0 0 16 16"
    color={props.color ?? '#0000008c'}
    iconId={ICON_ID}
  />
);

IconSearchLight.propTypes = {
  className: string,
  color: string,
};

export default IconSearchLight;
