import React from 'react';
import { oneOfType, arrayOf, node, string } from 'prop-types';

import classnames from 'classnames';

import { useSearch } from '../../../hooks/context';

const ToolbarItem = ({ children, className, namespace }) => {
  const { shops } = useSearch();
  const isShops = Boolean(shops);

  return (
    <li className={classnames(`${namespace}__action ${className}`, { 'shops__toolbar-action-lists': isShops })}>
      {children}
    </li>
  );
};

ToolbarItem.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired,
  className: string,
  namespace: string.isRequired,
};

ToolbarItem.defaultProps = {
  className: '',
};

export default ToolbarItem;
