import { useState } from 'react';

import { useStaticProps } from '../components/context/static-props';
import SearchUrlService from '../../services/search-url';

const handleError = (error) => {
  // eslint-disable-next-line no-console
  console.log(error);

  return null;
};

const useSearchUrl = () => {
  const [isLoading, setIsLoading] = useState(false);
  const StaticProps = useStaticProps();
  const { siteId } = StaticProps;

  const doSearch = (filterObject) => {
    setIsLoading(true);

    return SearchUrlService.getSearchUrl(filterObject, siteId, 'PIN')
      .then(({ data }) => data.url)
      .catch(handleError)
      .then((url) => {
        setIsLoading(false);

        return url;
      });
  };

  return [isLoading, doSearch];
};

export default useSearchUrl;
