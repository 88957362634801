import { useContext, useState } from 'react';

import { CurrencyContext } from '../../../context/currency';

const useCurrency = (convertedValues) => {
  const { selectedCurrency, setSelectedCurrency, currencies } = useContext(CurrencyContext);
  const [transformedValues, setTransformedValues] = useState(convertedValues[selectedCurrency.id]);

  const switchCurrency = (id) => {
    const newCurrency = currencies.find((c) => c.id === id);

    setSelectedCurrency(newCurrency);

    setTransformedValues(convertedValues[id]);
  };

  return [transformedValues, selectedCurrency, currencies, switchCurrency];
};

export { useCurrency };
