import React from 'react';
import { string } from 'prop-types';

import classnames from 'classnames';

import { BuildIcon } from '../utils';

const namespace = 'ui-search-icon ui-search-icon--gallery';

const IconGallery = (props) => (
  <BuildIcon {...props} className={classnames(namespace, props.className)} viewBox="0 0 32 32">
    <path d="M0 0h32v32h-32v-32zM29.333 29.333v-8h-26.667v8h26.667zM29.333 18.667v-16h-26.667v16h26.667z" />
  </BuildIcon>
);

IconGallery.propTypes = {
  className: string,
  color: string,
};

export default IconGallery;
