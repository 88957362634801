import React from 'react';
import { func, string } from 'prop-types';

import { Button } from '@andes/button';

/**
 * ButtonFaced Component
 * @param {Object} props - Component properties
 * @param {string} props.actionLabel - The label for the button
 * @param {function} props.handlerAction - The callback function to handle button click
 *
 * @returns {JSX.Element} The rendered button component
 */
const ButtonFaced = ({ actionLabel, handlerAction }) => (
  <Button readOnly="readonly" data-testid="faceted-search-action" onClick={handlerAction} fullWidth>
    {actionLabel}
  </Button>
);

ButtonFaced.propTypes = {
  actionLabel: string.isRequired,
  handlerAction: func.isRequired,
};

export default ButtonFaced;
