import React from 'react';
import { oneOfType, arrayOf, node, string, bool } from 'prop-types';

import classnames from 'classnames';

import { useSearch } from '../../hooks/context';
import useEshopsEnvironment from '../../hooks/use-eshops-environment';

const ToolbarMobile = ({ children, className, showBorder, namespace, hasSpotlight }) => {
  const { shops } = useSearch();
  const isShops = Boolean(shops);
  const { isEshopsEnvironment: isEshop } = useEshopsEnvironment();
  const childrenArr = React.Children.toArray(children).filter((child) => child);

  return (
    <div
      className={classnames(namespace, className, {
        [`${namespace}--two-btn`]: childrenArr.length === 2,
        [`${namespace}--border`]: showBorder,
        [`${namespace}--has-spotlight`]: hasSpotlight,
        [`${namespace}--eshops-shadow`]: isEshop,
        shops__toolbar: isShops,
      })}
    >
      <ul className={classnames(`${namespace}__actions`, { 'shops__toolbar-actions': isShops })}>{children}</ul>
    </div>
  );
};

ToolbarMobile.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired,
  className: string,
  hasSpotlight: bool,
  namespace: string.isRequired,
  showBorder: bool,
};

ToolbarMobile.defaultProps = {
  className: '',
  showBorder: false,
  hasSpotlight: false,
  filtering_options: null,
  sorting_options: null,
  dynamic: false,
};

export default ToolbarMobile;
