import React from 'react';
import { string, func, arrayOf, shape, objectOf } from 'prop-types';

import classNames from 'classnames';
import RadioButton, { RadioGroup } from '@andes/radio-button';

const namespace = 'ui-search-simple-selection-filters';

const SimpleSelection = ({ filterName, expandedValues, selectFilter, currentValue }) => (
  <div className={classNames(namespace)}>
    <RadioGroup onChange={selectFilter} name={filterName} selectedValue={currentValue} labelPosition="left">
      {expandedValues.map((value) => (
        <RadioButton id={value.id} key={value.name} value={value} name={filterName} label={value.name} />
      ))}
    </RadioGroup>
  </div>
);

SimpleSelection.propTypes = {
  currentValue: objectOf(string),
  expandedValues: arrayOf(
    shape({
      id: string,
      name: string,
      url: string,
      results: string,
    }),
  ),
  filterName: string,
  selectFilter: func,
};

SimpleSelection.defaultProps = {
  currentValue: undefined,
  filterName: '',
  selectFilter: '',
  expandedValues: [],
};

export default SimpleSelection;
