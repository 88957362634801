import React from 'react';
import { string } from 'prop-types';

import { useSearch } from '../../../hooks/context';
import DynamicFilters from '../../filters/dynamic/dynamic-filters.mobile';
import ToolbarItem from './toolbar-item.mobile';
import { FilterTooltip } from '../../../components-v2/sidebar/components/filters-tooltip/mobile';

const renderToolbarFilters = (namespace, filtering_options) => {
  const { labels, available_filters, applied_filters, url } = filtering_options;

  return (
    <ToolbarItem className={`${namespace}__action--filter`} namespace={namespace}>
      <DynamicFilters labels={labels} filters={available_filters} appliedFilters={applied_filters} url={url} icon />
      <FilterTooltip>
        {' '}
        <div className={`${namespace}__tooltip-trigger`} />
      </FilterTooltip>
    </ToolbarItem>
  );
};

const ToolbarFilters = ({ namespace }) => {
  const { filtering_options } = useSearch();

  return filtering_options ? renderToolbarFilters(namespace, filtering_options) : null;
};

ToolbarFilters.propTypes = {
  namespace: string.isRequired,
};

export default ToolbarFilters;
