import React from 'react';
import { string, func, arrayOf, shape, objectOf } from 'prop-types';

import classNames from 'classnames';
import RadioButton, { RadioGroup } from '@andes/radio-button';

const namespace = 'ui-search-simple-selection-sorts';

const SimpleSelectionSort = ({ filterName, sorting_options, selectFilter, currentValue }) => (
  <div className={classNames(namespace)}>
    <RadioGroup onChange={selectFilter} name={filterName} selectedValue={currentValue} labelPosition="left">
      {sorting_options.sorts.map((value) => (
        <RadioButton id={value.id} key={value.name} value={value} name={sorting_options.label} label={value.name} />
      ))}
    </RadioGroup>
  </div>
);

SimpleSelectionSort.propTypes = {
  currentValue: objectOf(string),
  filterName: string,
  selectFilter: func,
  sorting_options: shape({}),
  sorts: arrayOf(
    shape({
      id: string,
      name: string,
      disclaimer: string,
      selected: Boolean,
      has_disclaimer: Boolean,
    }),
  ),
};

SimpleSelectionSort.defaultProps = {
  currentValue: undefined,
  filterName: '',
  selectFilter: '',
  sorting_options: null,
};

export default SimpleSelectionSort;
