import React, { useState } from 'react';
import { node, arrayOf, shape } from 'prop-types';

const CurrencyContext = React.createContext(null);
const { Provider, Consumer: CurrencyConsumer } = CurrencyContext;

const CurrencyProvider = ({ children, currencies }) => {
  const [selectedCurrency, setSelectedCurrency] = useState(currencies ? currencies.find((c) => c.selected) : {});

  return <Provider value={{ selectedCurrency, setSelectedCurrency, currencies }}>{children}</Provider>;
};

CurrencyProvider.propTypes = {
  children: node.isRequired,
  currencies: arrayOf(shape({})).isRequired,
};

export { CurrencyContext, CurrencyProvider, CurrencyConsumer };
